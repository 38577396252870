<template>
	<main class="vcc-main vcc-main__kb">
		<h1 class="heading" hidden>Вопросы-ответы</h1>
		<h2 class="heading heading__hr hr__bold">База знаний</h2>
		<section class="materials-main">
			<section class="kb--aside">
				<nav class="kb--nav">
					<ul class="kb-nav--list">
						<li><router-link :to="{name: 'FaqExperts'}" class="link__menu">Вопросы экспертам дорожного хозяйства</router-link></li>
						<li><router-link :to="{name: 'FaqWebinras'}" class="link__menu">Вебинары</router-link></li>
						<li><router-link :to="{name: 'FaqKnowledgeBase'}" class="link__menu">База знаний</router-link></li>
					</ul>
				</nav>
				<form @submit.prevent="console.log('sended')" class="subscribe-form__ask" v-if="false">
					<fieldset>
						<legend>Задайте свой вопрос эксперту! Ответ будет опубликован на нашем сайте.</legend>
						<router-link :to="{ name: 'AskQuestionExpert' }" type="submit" class="link__btn btn__transparent-bg">Задать</router-link>
					</fieldset>
				</form>
			</section>
			<section class="list-wth-filters">
				<section class="search-section">
					<form @submit.prevent="">
						<label for="webinars-search" class="label__input">Поиск по названию</label>
						<input type="text" name="webinars-search" id="webinars-search" class="input" placeholder="Начните вводить название" v-model="search_req" list="webinars-titles">
						<div class="input-wrapper" v-show="false">
							<label for="webinars-filter">Фильтр по видам деятельности</label>
							<select name="webinars-filter" id="webinars-filter" class="select">
								<option value="Ценообразование">Ценообразование</option>
							</select>
							<span class="expand-icon"></span>
						</div>
					</form>
					<ul class="filters-wrapper" v-show="false">
						<li class="link__btn btn__filter btn__filter-name">
							Ценообразование
							<button type="button" class="btn__close"></button>
						</li>
					</ul>
					<section class="sorting-section">
						<hr class="hr__blue">
						<div class="sorting--info">
							<dl class="dl__theme sorting-section--dl">
								<dt>Соответствует запросу</dt>
								<dd><b>{{filteredItems.length}}</b></dd>
							</dl>
							<dl class="dl__theme sorting-section--dl">
								<dt>Всего в базе</dt>
								<dd><b>{{getQuestions.length}}</b></dd>
							</dl>
							<dl class="dl__theme dl--filter sorting-section--dl">
								<dt><b>Сортировать</b></dt>
								<dd>
									<div class="filter-group">
										<input type="radio" name="order" id="asc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="false">
										<label for="asc" class="sorting-by">с начала</label>
									</div>
									<div class="filter-group">
										<input type="radio" name="order" id="desc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="true">
										<label for="desc" class="sorting-by">с конца</label>
									</div>
									<div :class="['filter-group', {'chrome-date': isChrome }]">
										<input type="date" name="sort-date" id="filter-date" :class="['sort-date', 'visually-hidden', 'date-visually-hidden', {'date-not-empty': filter.date != null}]" v-model="filter.date">
										<label for="filter-date" class="sorting-by">
											по дате
											<svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 .66a.57.57 0 0 1-.21.45L4.36 3.87a.57.57 0 0 1-.73 0L.21 1.01A.57.57 0 0 1 .94.13L4 2.7 7.06.22A.57.57 0 0 1 8 .66Z" fill="#9C9D9D"/></svg>
										</label>
									</div>
								</dd>
							</dl>
						</div>
					</section>
				</section>
				<ul class="kb--list" v-if="filteredItems.length > 0">
					<li v-for="question, key in filteredItems" :key="'faq-kb-'+key">
						<vcc-kb-card :card-info="question" card-type="faq" :no-footer="true" />
					</li>
				</ul>
				<div class="no-webinars" v-else>
					<h4>По вашему запросу не нашлось вопросов по работе базы знаний.</h4>
					<p>Попробуйте применить другие фильтры или другой запрос.</p>
				</div>
			</section>
			<ul class="kb--list">
			</ul>
		</section>
	</main>
</template>

<script>
import KbCard from '@/components/cards/KbCard.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	data() {
		return {
			search_req: this.$route.query.search || null,
			filter: {
				date : null,
				sort_by: true,
			},
		}
	},
	components: {
		'vcc-kb-card': KbCard
	},
	computed: {
		...mapGetters(['getQuestions']),
		filteredItems() {
			const questions = this.getQuestions
			const searched_questions = this.search_req != null ? questions.filter(item => item.title.toLowerCase().includes(this.search_req.toLowerCase())) : questions
			return searched_questions.sort((a, b) => {
				if(this.filter.sort_by) {
					return b.id - a.id
				} else {
					return a.id - b.id
				}
			})
			.filter((item) => {
				if(this.filter.date !== null && this.filter.date !== '') {
					return this.isEventToday(item.created, this.filter.date) ? item : false
				} else {
					return item
				}
			})
			.filter((item) => {
				return item.question_type === 3
			})
		}
	},
	methods: mapActions(['fetchAllQuestions']),
	mounted() {
		this.fetchAllQuestions()
	}
}
</script>

<style>

</style>
